import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import infoIcon from "../../assets/images/pricing/info-icon.svg";
import Radiant_img1 from "../../assets/images/Shapes_Image/radiant1.svg";
import Radiant_img2 from "../../assets/images/Shapes_Image/radiant2.svg";
import Radiant_img3 from "../../assets/images/Shapes_Image/radiant3.svg";
import Radiant_img4 from "../../assets/images/Shapes_Image/radiant4.svg";
import Pear_img1 from "../../assets/images/Shapes_Image/pear4.svg";
import Pear_img2 from "../../assets/images/Shapes_Image/pear3.svg";
import Pear_img3 from "../../assets/images/Shapes_Image/pear2.svg";
import Pear_img4 from "../../assets/images/Shapes_Image/pear1.svg";
import Oval_img1 from "../../assets/images/Shapes_Image/Oval1.svg";
import Oval_img2 from "../../assets/images/Shapes_Image/Oval2.svg";
import Oval_img3 from "../../assets/images/Shapes_Image/Oval3.svg";
import Oval_img4 from "../../assets/images/Shapes_Image/Oval4.svg";
import Cusion_img1 from "../../assets/images/Shapes_Image/Cusion1.svg";
import Cusion_img2 from "../../assets/images/Shapes_Image/Cusion2.svg";
import Cusion_img3 from "../../assets/images/Shapes_Image/Cusion3.svg";
import Cusion_img4 from "../../assets/images/Shapes_Image/Cusion4.svg";
import Round_img1 from "../../assets/images/Shapes_Image/round.svg";
import Heart_img1 from "../../assets/images/Shapes_Image/heart3.svg";
import Heart_img2 from "../../assets/images/Shapes_Image/heart2.svg";
import Heart_img3 from "../../assets/images/Shapes_Image/heart1.svg";
import Emerald_img1 from "../../assets/images/Shapes_Image/Emerald1.svg";
import Emerald_img2 from "../../assets/images/Shapes_Image/Emerald2.svg";
import Emerald_img3 from "../../assets/images/Shapes_Image/Emerald3.svg";
import Emerald_img4 from "../../assets/images/Shapes_Image/Emerald4.svg";
import arrow from "../../assets/images/pricing/arrowLight.svg"; 
import { PriceContext } from "../../context";
import {  diamondSaturations, diamondShapes , diamondDesktopPolish,diamondDesktopSymmetry, diamondColors, diamondDepthForManual, diamondDepth, diamondColorInnerGrade, diamondColorDispersion, diamondColorUndertone, diamondFluorescenceColor, diamondFluorescenceColorSub, diamondSymmetry, clarity_T_Value_DESKTOP, diamondPolish } from "../../utils/diamondData";
import { calculateThreePercentLess, getPolishAndSymmetryValue } from "../../utils/function";
import {  useNavigate } from "react-router-dom";
import { priceIntelligenceInstance } from "../../config/axios";
import DesktopPairType from "./desktopPairType";
import InfoModal from "../infoModal/infoModal";
import { PriceIntelligence  } from "../../utils/dataPI";
import Header from "../header/header";
import ErrorModal from "../ErrorModal/errorModal";
import UAParser from 'ua-parser-js';
import Footer from "../footer/footer";

const DesktopPricing = ({action, priceData}) => {
  const getContext = useContext(PriceContext);
  const { setPricestepData, pricestepData, reportType , checkAssets , setCheckAssets , setReportType , setPICredits } = getContext;
  const navigate = useNavigate();
  const [infoPopupData,setInfoPopupData]=useState()
  const [openDropbox, setOpenDropbox] = useState("");
  const [disableDropbox, setDisableDropbox] = useState({});
  const [inputFields] = useState([
    'weight',
    'shape',
    'color',
    'saturation',
    'clarity',
    'fluorescence',
    'ratio',
    'polish',
    'symmetry',
    'trueFaceUp',
    'colorInnerGrade',
    'colorDispersion',
    'colorUndertone',
    'singlePair',
  ]);
  const [nextStep, setNextStep] = useState("weight");
  const [disableSelect , setDisableSelect] = useState(false);

  const dropdownRef = useRef();

  const [loading, setLoading] = useState(false);
  const [, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setCheckAssets(localStorage.getItem("checkAssets") === "true" ? true : false);
    if (action === "fromstartmanualpricing") {
      setReportType("byManual");
    }else{
      setReportType("byGia");
      setPricestepData(priceData);
    }

     // eslint-disable-next-line
  }, [action]);


  const handleClickOutside = (event) => {

    
    if (dropdownRef.current && dropdownRef.current.contains(event.target)  && !(openDropbox==="flourence" && event.target.className.includes("sub_drop"))  && !event.target.className.includes("form_select_drop") ) {
      setOpenDropbox("");
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('click', handleClickOutside, true);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line
  }, [openDropbox]);
  

  let shapes = {
    Radiant: [
      {
        text: "A",
        img: Radiant_img1,
        value: "1.00",
        upto:'1.09'
      },
      {
        text: "B",
        img: Radiant_img2,
        value: "1.10",
        upto:'1.19'
      },
      {
        text: "C",
        img: Radiant_img3,
        value: "1.20",
         upto:'1.29'
      },
      {
        text: "D",
        img: Radiant_img4,
        value: "1.30",
        upto:'1.30 +'
      },
    ],
    Pear: [
      {
        text: "A",
        img: Pear_img1,
        value: "1.30",
        upto: "1.39",
      },
      {
        text: "B",
        img: Pear_img2,
        value: "1.40",
        upto: "1.49",
      },
      {
        text: "C",
        img: Pear_img3,
        value: "1.50",
        upto: "1.59",
      },
      {
        text: "D",
        img: Pear_img4,
        value: "1.60",
        upto: "1.60 +",
      },
    ],
    Oval: [
      {
        text: "A",
        img: Oval_img1,
        value: "1.20",
        upto: "1.29",
      },
      {
        text: "B",
        img: Oval_img2,
        value: "1.30",
        upto: "1.39",
      },
      {
        text: "C",
        img: Oval_img3,
        value: "1.40",
        upto: "1.44",
      },
      {
        text: "D",
        img: Oval_img4,
        value: "1.45",
        upto: "1.45 +",
      },
    ],
    Cushion: [
      {
        text: "A",
        img: Cusion_img1,
        value: "1.00",
        upto: "1.09",
      },
      {
        text: "B",
        img: Cusion_img2,
        value: "1.10",
        upto: "1.19",
      },
      {
        text: "C",
        img: Cusion_img3,
        value: "1.20",
        upto: "1.29",
      },
      {
        text: "D",
        img: Cusion_img4,
        value: "1.30",
        upto: "1.30 +",
      },
    ],
    Round: [
      {
        text: "A",
        img: Round_img1,
        value: "1.00",
        upto: "1.00",
      },
    ],
    Heart: [
      {
        text: "A",
        img: Heart_img1,
        value: "0.95",
        upto: "0.99",
      },
      {
        text: "B",
        img: Heart_img2,
        value: "1.00",
        upto: "1.04",
      },
      {
        text: "C",
        img: Heart_img3,
        value: "1.05",
        upto: "1.05 +",
      },
    ],
    Emerald: [
      {
        text: "A",
        img: Emerald_img1,
        value: "1.00",
        upto: "1.19",
      },
      {
        text: "B",
        img: Emerald_img2,
        value: "1.20",
        upto: "1.29",
      },
      {
        text: "C",
        img: Emerald_img3,
        value: "1.30",
        upto: "1.39",
      },
      {
        text: "D",
        img: Emerald_img4,
        value: "1.40",
        upto: "1.40 +",
      },
    ],
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name==='trueFaceUp' && checkAssets) {
      setPricestepData({ ...pricestepData, [name]: value , colorDispersion : "2", colorUndertone : "3" , colorInnerGrade : "2" });
    }else{
      setPricestepData({ ...pricestepData, [name]: value });
    }
  };

  const handleWeight = (key, value) => {
    setNextStep("shape");
    setDisableSelect(false);
    if (value >= 1 && value <= 1.49) {
      setPricestepData({
        ...pricestepData,
        [key]: "1.01 - 1.49",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 1.5 && value <= 1.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "1.5 - 1.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 2 && value <= 2.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "2 - 2.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 3 && value <= 3.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "3 - 3.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 4 && value <= 4.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "4 - 4.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 5 && value <= 6.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "5 - 6.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 7 && value <= 7.99) {
      setPricestepData({
        ...pricestepData,
        [key]: "7 - 7.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 8 && value <10) {
      setPricestepData({
        ...pricestepData,
        [key]: "8 - 9.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 10 && value <11) {
      setPricestepData({
        ...pricestepData,
        [key]: "10 - 10.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 11 && value <14) {
      setPricestepData({
        ...pricestepData,
        [key]: "11 - 13.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 14 && value <17) {
      setPricestepData({
        ...pricestepData,
        [key]: "14 - 16.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 17 && value <20) {
      setPricestepData({
        ...pricestepData,
        [key]: "17 - 19.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 20 && value <25) {
      setPricestepData({
        ...pricestepData,
        [key]: "20 - 24.99",
        carat_weight: value,
      });
      setErrorMsg(false);
    } else if (value >= 25 && value <= 30) {
      setPricestepData({ ...pricestepData, [key]: "25>" 
      , carat_weight: value});
      setErrorMsg(false);
    } else {
      setErrorMsg(true);
      setPricestepData({ ...pricestepData, [key]: "" , carat_weight: value});
    }
  };

  const calculatePrice = async () => {
    try {
      setLoading(true);
      setLoader(true)
      const payload = { ...pricestepData };
      let { polish, symmetry } = payload || {};
      if (polish === "Default") {
        payload.polish = "Vg";
        payload.symmetry = "G";
        polish = "Vg";
        symmetry = "G";
      }

      payload.polishAndSymmetry = getPolishAndSymmetryValue(polish, symmetry);

      const depthItem = (reportType==='byGia'?diamondDepth :diamondDepthForManual).find(
        (item) => item.displayName === pricestepData.trueFaceUp
      );
      if (depthItem) {
        // Update the payload with the Id from the depthItem
        payload.trueFaceUp = Number(depthItem.Id);
      }
      const [ipResponse, uaResult] = await Promise.all([
        fetch("https://api.ipify.org?format=json"),
        new Promise((resolve) => resolve(new UAParser().getResult())),
      ]);
  
      const { ip } = await ipResponse.json();
  
      // Prepare the payload for the API
      const extraInfo = {
        IP: ip,
        "Operating System": uaResult.os.name,
        Browser: uaResult.browser.name,
        Device: uaResult.device.model || "Unknown",
      };
      
      const response = await priceIntelligenceInstance().post(
        `/price/getreport`,
        {payload , checkAssets , extraInfo},
      );
     
      setLoader(false)
      setTimeout(() => {
        setLoading(false);
      }, 4500);
      const { status, msg, price, jobNumber  , id , pdfurl, piCredits} = response?.data || {};
      if (status === 200) {
        let priceValue = price;
        if (pricestepData?.giasaturation === "Fancy Deep") {
          priceValue = price * 0.94;
        }
        const extraData = {
          ...pricestepData,
          ...payload,
          price: calculateThreePercentLess(priceValue , checkAssets) || 0,
          jobNumber,
          pdfurl
        };
        setPricestepData({
          ...extraData
        });
        setPICredits(piCredits);


        setTimeout(() => {
          navigate("/result" ,{state: {id , checkAsset: checkAssets ,
            pricetepData : {...extraData} ,
            reportType: reportType
          

          }});
        }, 4500);
      } else if (status === 400) {
        return;
      } else {
        setPricestepData({ ...pricestepData, price: 0 });
        setErrMsg(msg);
        setErrorModal(true);
      }
    } catch (error) {
    }
    
  };  
  const [selectColor,setSelectColor]=useState('None');
  const [accordianState, setAccordianState] = useState({});

 

  const handleColor = (el) => {
    if (el !== 'None') {
      setSelectColor(el);
setAccordianState({[el] : !accordianState[el]});
    } else {
      if (pricestepData?.shape==="Round"){
      setPricestepData({ ...pricestepData, fluorescence: "None", ratio: 1 , 
        
      }); 

       
      }else{
        setPricestepData({ ...pricestepData, fluorescence: "None" });
      }
      setOpenDropbox("");
    }

  };
  
  const handleSubColor = (el) => {
   
    if (pricestepData?.shape==="Round"){
      setPricestepData({ ...pricestepData, fluorescence: `${el} ${selectColor}` 
        , ratio: 1 
      });
    }else{
    setPricestepData({ ...pricestepData, fluorescence: `${el} ${selectColor}` });
    }
    setOpenDropbox("");
  };



  const SelectDropFlourence=()=>{
    if (!pricestepData?.clarity || reportType === "byGia"){
      setDisableSelect(true)
      setDisableDropbox((prev)=>{
        return {
          ...prev,
          fluorescence : true
        }
      })
      return
    }else{
      setDisableSelect(false)
      setNextStep("ratio")
      setDisableDropbox((prev)=>{
        return {
          ...prev,
          fluorescence : false
        }
      })
    }
    setOpenDropbox(openDropbox === 'flourence' ? "" : 'flourence');
  }

  function getValueByField(fieldName) {
    setInfoModal(true);
    let infoData=PriceIntelligence.find(item => item.field === fieldName);
    setInfoPopupData(infoData);
  }

  
  return (
    <div className="price-intelligence-layout" >
      <div className="start-pricing diamond-data-boxes">
        <Header title="new-header" loader={loading}/>
        {loading ? (
          <div className="desktop-result-screen result-loading">
          <div className="desktop-result-body">
          <LoaderResult />
          </div>
          </div>
        ) : (
          <>
            <div className="desktop_pricing">
          <div className="desktop_pricing_inner"   ref={dropdownRef}>
            <div className="desktop-pricing-text">
              <h1>select the diamond’s parameters</h1>
            {reportType==="byGia" && <p className="giaReportNum">GIA Report Number: {pricestepData?.giaReportNumber}</p>}  
            </div>

            <div className="desktop_pricing_grade">
              <div className="desktop_pricing_left">
                <div className="select-grid">
                  <div className="form-wrapper form-wrapper_pricing">
                    <Form.Group
                      className={`form-input-wrapper form-input-newwrapper flex-row gap-5`}
                    >
                      <p className="d-flex m-0 align-items-center gap-1">
                     
                      <Button onClick={() =>getValueByField("weight")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                        Weight
                      </p>
                      <div className="d-flex flex-column">
                      <Form.Control
                        type="text"
                        pattern="[0-9]*/^\d*\.?\d*$/"
                        inputMode="decimal"
                        placeholder="ENTER CARAT WEIGHT"
                        disabled={(reportType==="byGia" && pricestepData.weight)?true:false}
                        name="carat_weight"
                        onChange={(e)=>handleWeight("weight", e.target.value)}
                        value={pricestepData.carat_weight}
                        className={`${(reportType==="byGia" || !pricestepData.weight) ?"disable_inp_bg":""}`}
                      />
                      {errorMsg && (
          <span className="desktop-error-text pt-2">
            Please enter value between 1 and 30
          </span>
        )}
        </div>
                    </Form.Group>
                    
                    <Form.Group
                      className={`form-input-wrapper form-input-newwrapper flex-row gap-5`}
                    >
                      <p className="d-flex m-0 align-items-center gap-1">
                      <Button onClick={() => getValueByField("shape")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                        Shape
                      </p>
                      <SelectDropBox  options={diamondShapes} name={"shape"} setPricestepData={setPricestepData} pricestepData={pricestepData}  reportType={reportType}
                       setOpenDropbox={setOpenDropbox}
                       inputFields={inputFields}
                       openDropbox={openDropbox}
                       setDisableDropbox={setDisableDropbox}
                       disableDropbox={disableDropbox}
                       disableSelect={disableSelect}
                       setDisableSelect={setDisableSelect}
                       nextStep={nextStep}
                       setNextStep={setNextStep}
                      />

                    </Form.Group>
                
                    <Form.Group
                      className={`form-input-wrapper form-input-newwrapper flex-row gap-5`}
                    >
                      <p className="d-flex m-0 align-items-center gap-1">
                      <Button onClick={() => getValueByField("color")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                        Color
                      </p>
                      <SelectColorDropBox 
                        options={diamondColors}
                        name={"color"}
                        setPricestepData={setPricestepData}
                        pricestepData={pricestepData}
                        reportType={reportType}
                        setOpenDropbox={setOpenDropbox}
                        openDropbox={openDropbox}
                        setDisableDropbox={setDisableDropbox}
                        disableDropbox={disableDropbox}
                        disableSelect={disableSelect}
                        setDisableSelect={setDisableSelect}
                        nextStep={nextStep}
                        setNextStep={setNextStep}
                        
                      
                      
                      />
                    </Form.Group>
                    <Form.Group
                      className={`form-input-wrapper form-input-newwrapper flex-row gap-5`}
                    >
                      <p className="d-flex m-0 align-items-center gap-1">
                      <Button onClick={() => getValueByField("saturation")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                        Saturation
                      </p>
                      <SelectDropBox 
                        options={diamondSaturations}
                        name={"saturation"}
                        setPricestepData={setPricestepData}
                        pricestepData={pricestepData}
                        reportType={reportType}
                         setOpenDropbox={setOpenDropbox}
                        openDropbox={openDropbox}
                        inputFields={inputFields}
                        setDisableDropbox={setDisableDropbox}
                        disableDropbox={disableDropbox}
                        disableSelect={disableSelect}
                        setDisableSelect={setDisableSelect}
                        nextStep={nextStep}
                        setNextStep={setNextStep}

                      
                      />
                    </Form.Group>
                    <Form.Group
                      className={`form-input-wrapper form-input-newwrapper flex-row gap-5`}
                    >
                      <p className="d-flex m-0 align-items-center gap-1">
                      <Button onClick={() => getValueByField("clarity")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                        Clarity
                      </p>
                      <SelectDropBox  
                        options={clarity_T_Value_DESKTOP}
                        name={"clarity"}
                        setPricestepData={setPricestepData}
                        pricestepData={pricestepData}
                        reportType={reportType}
                        setOpenDropbox={setOpenDropbox}
                        openDropbox={openDropbox}
                        inputFields={inputFields}
                        setDisableDropbox={setDisableDropbox}
                        disableDropbox={disableDropbox}
                        disableSelect={disableSelect}
                        setDisableSelect={setDisableSelect}
                        nextStep={nextStep}
                        setNextStep={setNextStep}
                      
                      />
                    </Form.Group>
                    <Form.Group
                      className={`form-input-wrapper form-input-newwrapper flex-row gap-5`}
                    >
                      <p className="d-flex m-0 align-items-center gap-1">
                      <Button onClick={() => getValueByField("fluorescence")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                        Fluorescence
                      </p>
                     
                      <div className="form_select_container ">
      <div
        className={`${(disableSelect && nextStep ==='fluorescence') ? "disable_border" : ""}  ${
           openDropbox ==='flourence' ? "form_select_dropRotate" : ""
        }  form_select_drop ${(reportType === "byGia" || pricestepData?.clarity === "")? "disable_input" : ""}`}

        onClick={SelectDropFlourence}
      >
        {pricestepData?.fluorescence || "Select"}
      </div>
      <div
        className={ `fluorescence_select_dropdownBox ${
          openDropbox === "flourence" ? "form_select_dropdownBoxBlock floureceboxBlock" : ""
        } disable_input1 form_select_dropdownBox`}
      >
      {diamondFluorescenceColor.map((item) => {
          return (
            <div className="mb-3">
          <div className="d-flex flex-column w-100">
          <h6 className={`${ accordianState[item] ? "form_select_dropRotate" : ""} ${item!=='None' && 'form_select_drop'} sub_drop`} onClick={() => handleColor(item)}>{item}</h6>
          <div className={`${ accordianState[item]   ?"open_wrapper":"open_wrapper1" } open_wrapper1 sub-option-wrapper`}>
          {diamondFluorescenceColorSub.map((subEl, j) => (
            <p
              className="pi-sub-option-elem"
              onClick={() => handleSubColor(subEl)}
              key={j}
            >
              {subEl} {item}
            </p>
          ))}
          </div>
          </div>
       
        </div>
          )
        })}
       
      </div>
    </div>
                    </Form.Group>
                    <Form.Group
                      className={`form-input-wrapper disable-ratio form-input-newwrapper flex-row gap-5 `}
                    >
                      <p className="d-flex m-0 align-items-center gap-1">
                      <Button onClick={() => getValueByField("Ratio")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                        Ratio
                      </p>
                      <div className="shapes_container shapes-wd ">
                        {(reportType === "byGia" || pricestepData?.shape === "Round") ?   <>
                          <Form.Control
                        type="text"
                        pattern="[0-9]*/^\d*\.?\d*$/"
                        inputMode="decimal"
                        placeholder="5.05"
                        name="ratio"
                        disabled={ true }
                        onChange={handleOnChange}
                        value={pricestepData.shape === 'Round' ? 'N/A' : pricestepData.ratio}
                        className="disable_inp_bg"
                      />
                        </>:<>
                        {shapes[pricestepData?.shape || "Cushion"].map((item, ind) => {
                          return (
                            <div className="shape_radiobtn">
                              <label className={`shape_box shape_box${ind} `} htmlFor={`test3-${ind}`}  onClick={()=> setDisableSelect(true)}>
                                <div>
                                <span className="text-decoration">{`${item.upto.includes("+") ? item.upto : "Up to " + item.upto}`}</span>
                                <img src={item.img} alt="shape_img"  />
                                </div>
                              </label>
                             
                              <input
                                type="radio"
                                id={`test3-${ind}`}
                                name="radio-group"
                                value={item.value}
                                disabled={!pricestepData.fluorescence}
                                checked={pricestepData?.ratio === item.value}
                                onChange={(e) => {
                                  if (!checkAssets) {
                                  setPricestepData({
                                    ...pricestepData,
                                    ratio: item.value,
                                    polish: "Vg",
                                    symmetry: "G"

                                  })
                                  }else{
                                    setPricestepData({
                                      ...pricestepData,
                                      ratio: item.value,
                                       polish: "Vg",
                                    symmetry: "G",
                                    colorDispersion : "3", colorUndertone : "3" , colorInnerGrade : "2" , trueFaceUp:"Face-Up - Standard" ,
         singlePair:"single"
                                    })
                                  }
                                }}
                              />
                             
                            </div>
                          );
                        })}

                        </>}
                    
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="desktop_pricing_right">
                <div className="select-grid">
                  <div className="form-wrapper form-wrapper_pricing">
                    <Form.Group
                      className={`form-input-wrapper form-input-newwrapper flex-row gap-5`}
                    >
                      <p className="d-flex m-0 align-items-center gap-1">
                      <Button onClick={()=>{getValueByField("polish")}} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                        polish
                      </p>
                      <SelectCodeDropBox  
                        options={diamondDesktopPolish}
                        name={"polish"}
                        setPricestepData={setPricestepData}
                        pricestepData={pricestepData}
                        reportType={reportType}
                        setOpenDropbox={setOpenDropbox}
                        openDropbox={openDropbox}
                        setDisableDropbox={setDisableDropbox}
                        disableDropbox={disableDropbox}
                        disableSelect={disableSelect}
                        setDisableSelect={setDisableSelect}
                        nextStep={nextStep}
                        setNextStep={setNextStep}
                        
                      
                      
                      />
                    </Form.Group>
                    <Form.Group
                      className={`form-input-wrapper form-input-newwrapper flex-row gap-5`}
                    >
                      <p className="d-flex m-0 align-items-center gap-1">
                      <Button onClick={() => getValueByField("symmetry")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                        symmetry
                      </p>
                      <SelectCodeDropBox
                        options={pricestepData?.polish === "Default" ? diamondDesktopSymmetry : diamondSymmetry}
                        name={"symmetry"}
                        setPricestepData={setPricestepData}
                        pricestepData={pricestepData}
                        checkAssets={checkAssets}
                        reportType={reportType}
                        setOpenDropbox={setOpenDropbox}
                        openDropbox={openDropbox}
                        inputFields={inputFields}
                        setDisableDropbox={setDisableDropbox}
                        disableDropbox={disableDropbox}
                        disableSelect={disableSelect}
                        setDisableSelect={setDisableSelect}
                        nextStep={nextStep}
                        setNextStep={setNextStep}
                      
                      />
                    </Form.Group>
                  </div>
                  <div className="form-wrapper_outer">
                    <div className="form-wrapper form-wrapper_pricing form-wrapper_blue">
                   { checkAssets ? <span>Visual assets - Default</span> : <span>select the diamond’s parameters</span>}   
                  {reportType === "byGia" && !checkAssets&& 
                   <Form.Group
                        className={`form-input-wrapper form-input-radiowrapper flex-row gap-51 w-100`}
                      >
                        <p className="d-flex m-0 align-items-center gap-1">
                        <Button onClick={() => getValueByField("TRUE-FACE-UP")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                          true face-up
                        </p>
                        <div className="shapes_container shapes-wd ">
                            <Form.Control
                        type="text"
                        pattern="[0-9]*/^\d*\.?\d*$/"
                        inputMode="decimal"
                        disabled = {true}
                        name="trueFaceUp"
                        onChange={handleOnChange}
                        value={typeof pricestepData.trueFaceUp === "string" && pricestepData?.trueFaceUp?.includes("Face-Up - ") ? pricestepData?.trueFaceUp?.split("Face-Up - ")[1] : pricestepData.trueFaceUp}
                      />

                       
                        </div>
                      </Form.Group>
                      }   
                      {reportType !== "byGia" && !checkAssets&&  <Form.Group
                        className={`form-input-wrapper form-input-radiowrapper flex-row gap-51 w-100`}
                      >
                        <p className="d-flex m-0 align-items-center gap-1">
                        <Button onClick={() => getValueByField("TRUE-FACE-UP")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                          true face-up
                        </p>
                        <div className={`shapes_container shapes-wd ${pricestepData?.shape === "Round" ? "true_face_wd" : "true_face_wd_without_round "}`}>
                            { diamondDepthForManual?.map(
                            (item, ind) => {
                              return (
                                <div className="shape_radiobtn">
                                 {(pricestepData?.shape === "Round"  && item.displayName === "Face-Up - Large") ? < ></> : <label className="m-0" onClick={()=> setDisableSelect(true)}>{item.displayName?.includes("Face-Up - ") ? item.displayName.split("Face-Up - ")[1] : item.displayName}</label>}    
                                  <input
                                    type="radio"
                                    id="diamond_depth"  
                                    name="trueFaceUp"
                                    disabled={!pricestepData.symmetry}
                                    hidden={item.displayName === "Face-Up - Large" && pricestepData?.shape === "Round"}
                                    checked={pricestepData?.trueFaceUp === item.displayName}
                                    onChange={(e) => {
                                      if (checkAssets) {
                                        setPricestepData({
                                          ...pricestepData,
                                          trueFaceUp:  item?.displayName,
                                          colorDispersion : "3", colorUndertone : "3" , colorInnerGrade : "2" 
                                        });
                                      }else{
                                       
                                        setPricestepData({
                                          ...pricestepData,
                                          trueFaceUp: item?.displayName,
  
                                        });
                                      }
                                     
                                    }}
                                  />
                                </div>
                              );
                            }
                          )}
                       
                        </div>
                      </Form.Group>}   
                  
                      <Form.Group
                        className={`form-input-wrapper form-input-radiowrapper flex-row gap-51 w-100`}
                      >
                        <p className="d-flex m-0 align-items-center gap-1">
                        <Button onClick={() => getValueByField("color Inner-Grade")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                          inner - grade
                        </p>
                        <div className="shapes_container shapes-wd">
                      
                         
                        {diamondColorInnerGrade?.map((item, ind) => {

                            return (
                              <div className="shape_radiobtn">
                              {(pricestepData?.saturation !== "Fancy Vivid"  && item === "4+") ? <p  className="invisible" >{item}</p> : <label className="m-0" for={`${item}-id`} onClick={()=> setDisableSelect(true)}>{item}</label>}  
                                <input
                                  type="radio"
                                  id={`${item}-id`}
                                  name="diamond_color_inner"
                                  hidden={item === "4+" && pricestepData?.saturation !== "Fancy Vivid"}
                                  disabled={!pricestepData.trueFaceUp || checkAssets || (item === "4+" && pricestepData?.saturation !== "Fancy Vivid")}
                                  value={item}
                                  checked={pricestepData?.colorInnerGrade === item}
                                  onChange={(e) => {
                                    setPricestepData({
                                      ...pricestepData,

                                      colorInnerGrade: item,
                                    });
                                  }}
                                />
                              </div>
                            );
                          })}
                        
                        </div>
                      </Form.Group>
                      <Form.Group
                        className={`form-input-wrapper form-input-radiowrapper flex-row gap-51 w-100`}
                      >
                        <p className="d-flex m-0 align-items-center gap-1">
                        <Button onClick={() => getValueByField("color Dispersion")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                          dispersion
                        </p>
                        <div className="shapes_container shapes-wd">
                        {diamondColorDispersion.map((item, ind) => {
                                return (
                                  <div className="shape_radiobtn">
                                    {(pricestepData?.shape === "Round"  && item === "4+") ? <p className="invisible" >{item}</p> : <label for={`${item}-dispersion`} className="m-0" onClick={()=> setDisableSelect(true)}>{item}</label>}  
                                    <input
                                      type="radio"
                                      id ={`${item}-dispersion`}
                                      name="diamond_color_dispersion"
                                      value={item}
                                      hidden={item === "4+" && pricestepData?.shape === "Round"}
                                      disabled={!pricestepData.colorInnerGrade || checkAssets}
                                      checked={pricestepData?.colorDispersion === item}
                                      onChange={(e) => {
                                        setPricestepData({
                                          ...pricestepData,
                                          colorDispersion: item,
                                        });
                                      }}
    
                                    />
                                  </div>
                                );
                              })}
                        </div>
                      </Form.Group>
                      <Form.Group
                        className={`form-input-wrapper form-input-radiowrapper flex-row gap-51 w-100`}
                      >
                        <p className="d-flex m-0 align-items-center gap-1">
                        <Button onClick={() => getValueByField("color Undertone")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                          undertone
                        </p>
                        <div className="shapes_container shapes-wd">
                        {diamondColorUndertone?.map((item, ind) => {
                                return (
                                  <div className="shape_radiobtn">
                                 {pricestepData?.color !== "Pink" && item.value === "4+" ? < ></> : <label onClick={()=> setDisableSelect(true)} for={`${item?.value}-undertone`} className="m-0">{item?.value}</label>}   
                                    <input
                                      type="radio"
                                      id={`${item?.value}-undertone`}
                                      name="diamond_color-undertone"
                                      value={item.value}
                                      hidden={pricestepData?.color !== "Pink" && item.value === "4+"}
                                      disabled={!pricestepData.colorDispersion || checkAssets || (pricestepData?.color !== "Pink" && item.value === "4+")}
                                      checked={pricestepData?.colorUndertone === item?.value}
                                      onChange={(e) => {
                                        setPricestepData({
                                          ...pricestepData,
                                          colorUndertone: item?.value,
                                          singlePair: "single",
                                        });
                                      }}
                                    />
                                  </div>
                                );
                              })}
                        </div>
                      </Form.Group>
                      { (checkAssets  && reportType !== "byGia")  &&
                      <Form.Group
                        className={`form-input-wrapper form-input-radiowrapper flex-row gap-51 w-100`}
                      >
                        <p className="d-flex m-0 align-items-center gap-1">
                        <Button onClick={() => getValueByField("TRUE-FACE-UP")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                          true face-up
                        </p>
                        <div className={`shapes_container shapes-wd ${pricestepData?.shape === "Round" ? "true_face_wd" : "true_face_wd_without_round "} `}>
                          
                        { diamondDepthForManual?.map(
                            (item, ind) => {
                              return (
                                <div className="shape_radiobtn">
                                 {(pricestepData?.shape === "Round"  && item.displayName === "Face-Up - Large") ? < ></> : <label onClick={()=> setDisableSelect(true)} for="diamond_depth" className="m-0">{item.displayName?.includes("Face-Up - ") ? item.displayName.split("Face-Up - ")[1] : item.displayName}</label>}    
                                  <input
                                    type="radio"
                                    id="diamond_depth"  
                                    name="trueFaceUp"
                                    disabled={true}
                                    hidden={item.displayName === "Face-Up - Large" && pricestepData?.shape === "Round"}
                                    checked={pricestepData?.trueFaceUp === item.displayName}
                                    onChange={(e) => {
                                      if (checkAssets) {
                                     
                                        setPricestepData({
                                          ...pricestepData,
                                          trueFaceUp:  item?.displayName,
                                          colorDispersion : "3", colorUndertone : "3" , colorInnerGrade : "2" 
                                        });
                                      }else{
                                       
                                        setPricestepData({
                                          ...pricestepData,
                                          trueFaceUp: item?.displayName,
  
                                        });
                                      }
                                     
                                    }}
                                  />
                                </div>
                              );
                            }
                          )}
                      
                        </div>
                      </Form.Group>
                      } 

                      { (checkAssets &&  reportType === "byGia") &&
                      <Form.Group
                      className={`form-input-wrapper form-input-radiowrapper flex-row gap-51 w-100`}
                    >
                      <p className="d-flex m-0 align-items-center gap-1">
                      <Button onClick={() => getValueByField("TRUE-FACE-UP")} className="btn_info_icon">
                      <img
                        src={infoIcon}
                        alt="info_icon"
                        height="25px"
                        width="25px"
                      />
                    </Button>
                        true face-up
                      </p>
                      <div className="shapes_container shapes-wd ">
                          <Form.Control
                      type="text"
                      pattern="[0-9]*/^\d*\.?\d*$/"
                      inputMode="decimal"
                      disabled = {true}
                      name="trueFaceUp"
                      onChange={handleOnChange}
                      value={typeof pricestepData.trueFaceUp === "string" && pricestepData?.trueFaceUp?.includes("Face-Up - ") ? pricestepData?.trueFaceUp?.split("Face-Up - ")[1] : pricestepData.trueFaceUp}
                    />

                     
                      </div>
                    </Form.Group>}
                    </div>
                  
                  </div>
                        
                  <div className="form-wrapper form-wrapper_pricing">
                    <Form.Group
                      className={`form-input-wrapper form-input-newwrapper flex-row gap-5`}
                    >
                      <p className="d-flex m-0 align-items-center gap-1">
                      <Button onClick={() => getValueByField("single/pair")} className="btn_info_icon">
                        <img
                          src={infoIcon}
                          alt="info_icon"
                          height="25px"
                          width="25px"
                        />
                      </Button>
                        Single/Pair
                      </p>
                      <div className="new_toggle_box">
                        <DesktopPairType name="singlePair" reportType={reportType} pricestepData={pricestepData} setPricestepData={setPricestepData} setDisableSelect={setDisableSelect} from="desktop" />
                      </div>
                    </Form.Group>

                    
                  </div>
                  <div className="form-wrapper form-wrapper_pricing">
                    <Form.Group
                      className={`form-input-wrapper form-input-newwrapper calculate_btn flex-row justify-content-end gap-5`}
                    >
                      <div className="new_toggle_box">
                        <div className="pricing-userlogin-content-btn pricing-disclaimer-btn calculate_price_btn">
                          <Button onClick={() => calculatePrice()}  disabled={!pricestepData?.weight || !pricestepData?.shape || !pricestepData?.color || !pricestepData?.saturation || !pricestepData?.fluorescence || !pricestepData?.ratio ||  !pricestepData?.polish  || !pricestepData?.symmetry || !pricestepData?.trueFaceUp || !pricestepData?.colorUndertone || !pricestepData?.colorDispersion || !pricestepData?.colorInnerGrade || !pricestepData?.singlePair  }>
                            <span>calculate price </span>
                            <img src={arrow} alt="arrow" />
                          </Button>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          </>
        )}
    
      </div>
      <Footer />
{infoModal && 
      <InfoModal
          infoModal={infoModal}
          setInfoModal={setInfoModal}
          heading={infoPopupData?.field}
          infoTxt={infoPopupData?.info}
          bg_remove_modal="bg_remove_modal"
        />
      }

     
      <ErrorModal
          errorModal={errorModal}
          setErrorModal={setErrorModal}
          errMsg={errMsg}
           bg_remove_modal="bg_remove_modal"

        />
      
    </div>
  );
};
export default DesktopPricing;

const SelectDropBox = ({ options = [] , name ,pricestepData,setPricestepData , reportType, openDropbox, setOpenDropbox ,inputFields , disableDropbox , setDisableDropbox  
,
  disableSelect,
  setDisableSelect,
  nextStep,
  setNextStep,
 }) => {
  const checkAssets = localStorage.getItem("checkAssets") === "true" ? true : false
  
  const SelectToggle = () => {
  
    if (reportType === "byGia" && pricestepData[name] !== "") {
      return;
    }

 
    if (name==='shape'){   

      if (!pricestepData?.carat_weight) {
        setDisableSelect(true)
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : true
          }
        })
        return;
      }else{
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : false
          }
        })
      }
    }
    if (name === 'saturation'){
      if (!pricestepData?.color) {
        setDisableSelect(true)
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : true
          }
        })
        return;
      }else{
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : false
          }
        })
      }
    }

    if (name === 'clarity'){
      if (!pricestepData?.saturation) {
        setDisableSelect(true)
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : true
          }
        })
        return;
      }else{
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : false
          }
        })
      }
    }
      

    if (name === 'fluorescence'){
      if (!pricestepData?.clarity) {
        setDisableSelect(true)

        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : true
          }
        })
          
        return; 
      }else{
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : false
          }
        })
      }
    }



   

    setOpenDropbox(openDropbox === name ? "" : name);
   
  };

  const handleSelect = (value, name) => {
    if (name === 'saturation') {
      setPricestepData({ ...pricestepData, [name]: value ,colorInnerGrade: checkAssets ? pricestepData?.colorInnerGrade : pricestepData?.colorInnerGrade ==="4+" ? "": pricestepData?.colorInnerGrade });
    }else if(name === 'shape' && value ==="Round") {
      setPricestepData({ ...pricestepData, [name]: value , trueFaceUp: pricestepData?.trueFaceUp ==="Face-Up - Large" ? "": pricestepData?.trueFaceUp 
        , colorDispersion: pricestepData?.colorDispersion ==="4+" ? "": pricestepData?.colorDispersion
      });

    }else if ( name === 'clarity' && pricestepData?.shape ==="Round") { 
      setPricestepData({ ...pricestepData, [name]: value  , fluorescence: pricestepData?.fluorescence ==="" ? "None": pricestepData?.fluorescence , polish: pricestepData?.polish ==="" ? "Vg": pricestepData?.polish ,
        symmetry: pricestepData?.symmetry ==="" ? "G": pricestepData?.symmetry , ratio: pricestepData?.ratio ==="" ? "1": pricestepData?.ratio 
       });
    
    }else if ( name === 'clarity' && pricestepData?.shape !=="Round") { 
      setPricestepData({ ...pricestepData, [name]: value  , fluorescence: pricestepData?.fluorescence ==="" ? "None": pricestepData?.fluorescence });
    

    }else {
      setPricestepData({ ...pricestepData, [name]: value });

    }
    
    setOpenDropbox("");
    setDisableSelect(false);
    if ( name === "shape" ) {
      setNextStep("color")
      
    }else if (name === "saturation") {
      setNextStep("clarity")
    }else if (name === "clarity") {
      setNextStep("")
    }
    
  };
 

  return (

    <>
    <div className={`form_select_container `}>
      <div
        className={`${(disableSelect && nextStep===name) ? "disable_border" : ""} ${
          openDropbox === name ? "form_select_dropRotate" : ""
        } form_select_drop ${(reportType === "byGia" ||     pricestepData[inputFields[inputFields.indexOf(name)-1]] === "")? "disable_input" : ""}` }
        onClick={()=>SelectToggle()}
      >
        {pricestepData?.[name] || "Select"}
      </div>
      <div
        className={ ` disable_input1 ${
          openDropbox===name ? "form_select_dropdownBoxBlock" : ""
        } form_select_dropdownBox`}
      >
        {options.map((item) => {
          return (
            <h6
              className={`form_select_dropItem ${pricestepData[name] === item ? "selectedText" : ""}`}
              onClick={() => handleSelect(item, name)}
            >
              {item} 
            </h6>
          );
        })}
      </div>
    </div>
   
    </>
  );
};

const SelectCodeDropBox = ({ options = [] , name ,pricestepData,setPricestepData  , reportType ,openDropbox, setOpenDropbox , inputFields , disableDropbox , setDisableDropbox  , disableSelect , setDisableSelect , nextStep , setNextStep }) => {

  const checkAssets = localStorage.getItem("checkAssets") === "true" ? true : false

  const SelectToggle = () => {
    if (reportType === "byGia" && pricestepData[name] !== "") {
      return;
    }

    if (name ==='polish') {
      if (!pricestepData?.ratio) {
        setDisableSelect(true)
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : true
          }
        })
        return;
      }else{
        
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : false
          }
        })
      }
    }
    
    if (name ==='symmetry') {
      if (!pricestepData?.polish) {
        setDisableSelect(true)
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : true
          }
        })
        return;
      }else{
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : false
          }
        })
      }
    }
    if (name ==='color') {
      if (!pricestepData?.polish) {
        setDisableSelect(true)
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : true
          }
        })
        return;
      }else{
        setDisableSelect(false)
        setNextStep("saturation")
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : false
          }
        })
      }
    }
    setOpenDropbox(openDropbox === name ? "" : name);
  };

  const handleSelect = (value, name) => {
    if (name === "symmetry"  && pricestepData?.polish === 'Default') {
      setOpenDropbox("");
      return
    }

 
    if (name === "symmetry"  && checkAssets) {
      setPricestepData({ ...pricestepData, [name]: value , colorDispersion : "3", colorUndertone : "3" , colorInnerGrade : "2" , trueFaceUp:"Face-Up - Standard" ,
         singlePair:"single"

      });
    }else if (name === "polish"  && value ==='Default'  && checkAssets) {  
      setPricestepData({ ...pricestepData, [name]: value  , symmetry: "Default" ,colorDispersion : "3", colorUndertone : "3" , colorInnerGrade : "2" , trueFaceUp:"Face-Up - Standard" , 
         singlePair:"single"});
    }else if (name === "polish"  && value ==='Default'  ) {
      setPricestepData({ ...pricestepData, [name]: value  , symmetry: "Default"  });
    }else if (name === "polish"  && value !=='Default'  ) {
      setPricestepData({ ...pricestepData, [name]: value   });
    }
        
    else{
      setPricestepData({ ...pricestepData, [name]: value });
    }
    setOpenDropbox("");
    setDisableSelect(false);
    if ( name === "polish") {
      setNextStep("symmetry")
    }
  };

  const getDisplayName = (value) => {
    if (name === "polish") {
    return  diamondPolish?.find((s) => s.code === value)?.displayName
      
    }else if (name === "symmetry") {
      return  diamondSymmetry?.find((s) => s.code === value)?.displayName
    }
    
  }

  return (
    <div className="form_select_container">
      <div
        className={` ${(disableSelect && name=== nextStep) ? "disable_border" : ""}  ${
          openDropbox===name ? "form_select_dropRotate" : ""
        } form_select_drop ${(reportType === "byGia"   || (name==='polish' && pricestepData?.ratio==="")  || (name === 'symmetry' && (pricestepData?.polish === "")))? "disable_input" : ""}`}
        onClick={SelectToggle}
      >
        {getDisplayName(pricestepData?.[name])  || "Select"}
      </div>
      <div
        className={`${
          openDropbox===name ? "form_select_dropdownBoxBlock" : ""
        } form_select_dropdownBox`}
      >
        {options.map((item) => {
          return (
            <h6
              className={`form_select_dropItem ${pricestepData?.[name] === item?.code ? "selectedText" : ""}   ${name==='symmetry' && (pricestepData?.polish === 'Default' || pricestepData?.polish === "") && item?.code !== "Default"? "disable_input" : ""}`}
              onClick={() => handleSelect(item?.code, name)}
            >
              {item?.displayName}
            </h6>
          );
        })}
      </div>
    </div>
  );
};



const SelectColorDropBox = ({ options = [] , name ,pricestepData,setPricestepData , reportType , openDropbox, setOpenDropbox , setDisableDropbox , disableDropbox 

 , disableSelect , setDisableSelect , nextStep , setNextStep
}) => {
  const checkAssets = localStorage.getItem("checkAssets") === "true" ? true : false
  const [, setDefaultColor] = useState({
    img: "",
    value: "",
  });
  const { color } = pricestepData || {};

  useEffect(()=>{
    setDefaultColor({
      img: color ? color?.toLowerCase() : "",
      value:  color ? color : "Select",
    });
    setPricestepData({ ...pricestepData, "color": color });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const SelectToggle = () => {
    if (reportType === "byGia" && pricestepData["shape"] !== "") {
      return;
    }
    if (name ==='color'){
      if (!pricestepData?.shape) {
        setDisableSelect(true)
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : true
          }
        })
        return;
      }else{
       
        setDisableDropbox((prev)=>{
          return {
            ...prev,
            [name] : false
          }
        })
      }
    }
    setOpenDropbox(openDropbox === name ? "" : name);
  };

  const handleSelect = (image,value, name) => {
    setDefaultColor({ img: image, value: value });
    setPricestepData({ ...pricestepData, [name]: value  , colorUndertone:checkAssets ? pricestepData?.colorUndertone : pricestepData?.colorUndertone==="4+" ? "":pricestepData?.colorUndertone });
    setOpenDropbox("");
    setDisableSelect(false)
    setNextStep("saturation")
  };

  return (
    <div className="form_select_container">
      <div
        className={`${(disableSelect && name=== nextStep) ? "disable_border" : ""}  ${
          openDropbox===name ? "form_select_dropRotate" : ""
        } form_select_drop ${(reportType === "byGia" || pricestepData["shape"] === "")? "disable_input" : ""}`}
        onClick={SelectToggle}
      >
         {pricestepData?.[name] ? diamondColors.find((item)=>item?.value === pricestepData?.[name])?.name : "Select"}
      </div>
      <div
        className={`${
          openDropbox ===name ? "form_select_dropdownBoxBlock" : ""
        } form_select_dropdownBox`}
      >
        {options.map((item) => {
          return (
          <>
            <h6
              className={`form_select_dropItem ${pricestepData[name] === item?.value ? "selectedText" : ""}`}
              onClick={() => handleSelect(item?.img,item?.value, name)}
            >
              {item?.name}
            </h6></>
          );
        })}
      </div>


    </div>
  );
};
let count = 0
const LoaderResult=()=>{
  const [loaderTxt, setLoaderTxt] = useState("Analyzing characteristics");
  const textAr = ["Assessing price per carat", "Here is the price range"];

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 2) {
        setLoaderTxt(textAr[count]);
      } else {
        clearInterval(interval);
      }

      count += 1;
    }, 1500);
    return () => {
      clearInterval(interval);
      count = 0;
    };
     // eslint-disable-next-line
  }, []);
 

  return(
    <>
    <div class="loaderContainer" bis_skin_checked="1">
      <h6>Price Per Carat:</h6>
      <div class="spinner" bis_skin_checked="1"></div>
      <p>{loaderTxt}</p>
      </div>
    </>
  )
}