import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PriceIntelligenceLayout from "..";
import { Button, Spinner } from "react-bootstrap";
import arrow from "../../assets/images/pricing/arrow.svg";

import arrow1 from "../../assets/images/pricing/arrow.svg";
import bannerBg from "../../assets/images/pricing/banner_desktop.png";
import { priceIntelligenceInstance } from "../../config/axios";
import { PriceContext } from "../../context";

import { checkGIAReport, isShapeRound } from "../../utils/function";
import Home from "./home";
import { diamondPolish, diamondSymmetry } from "../../utils/diamondData";
import ErrorModal from "../ErrorModal/errorModal";
import menuicon from "../../assets/images/pricing/menuIcon.svg";
import searchHistory from "../../assets/images/pricing/search-icon.svg";
import creditIcon from "../../assets/images/pricing/credit.svg";
import DisclaimerModal from "../disclaimer/disclamerModal";
import Footer from "../footer/footer";

const StartPricing = () => {
  const getContext = useContext(PriceContext);
  const {
    initPayload,
    setPricestepData,
    setGiaReport,
    setIsManualPricing,
    setWidthValue,
    setLengthValue,
    setReportType,
    checkAssets, setCheckAssets,
    piCredits,
    setPICredits
  } = getContext;
  const [loading, setLoading] = useState(false);
  const [disclaimerModal, setDisclaimerModal] = useState(false);
  const [reportnum, setReportnum] = useState("");
  const [showNextBtn, setShowNextBtn] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);

  const [showButton, setShowButton] = useState(true);
  const [GiaNum, setGiaNum] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  let navigate = useNavigate();



  useEffect(() => {
    console.log("loader", loader);
    if (loader) {
      const interval = setInterval(() => {
        window.parent.postMessage("iframeIsReadyToReceiveUserData", "*");
      }, 1000);

      const handleMessage = (event) => {
        console.log("event.origin1", event.origin);
        console.log("event.origin2", process.env.REACT_APP_WP_BASE_URL);
        if (event.origin === process.env.REACT_APP_WP_BASE_URL) {
          const {
            token,
          } = event?.data;
          console.log("token come form wp diary==>", token);
          console.log("event?.data==>", event?.data);
          if (token) {
            localStorage.setItem("fcrf@token", token);
            GetPICreditsData();
            setLoader(false);
          } else {
            localStorage.removeItem("fcrf@token");
          }
        } else {

          if (!localStorage.getItem("fcrf@token")) {
            console.log("window.location", window.location);
            console.log("window.parent.location", window.parent.location);
            if (window.location !== window.parent.location) {
              // The page is in an iframe
            } else {
              // The page is not in an iframe
              window.location.href = process.env.REACT_APP_WP_APP_LOGIN;
            }
          }
        }

        if (event?.data) {
          clearInterval(interval);
        }
      };

      window.addEventListener("message", handleMessage, false);

      return () => {
        window.removeEventListener("message", handleMessage);
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader, location]);

  useEffect(() => {
    setPricestepData(initPayload);
    setCheckAssets(localStorage.getItem("checkAssets") === "true" ? true : false);
    // eslint-disable-next-line
  }, []);

  const GetPICreditsData = async () => {
    try {
      const response = await priceIntelligenceInstance().get(
        `/price/get-pi-credits`
      );

      if (response?.data?.status === 200) {
        setPICredits(response?.data?.pi_credits);
        setDisclaimerModal(!response?.data?.pi_disclaimer_accepted);
      } else {
        setPICredits(null);
      }
    } catch (e) {
console.log("e", e);
    }
  };





  const handleShowReport = async () => {
    try {
      setButtonDisable(true);
      setReportType("byGia");
      setLoading(true);
      if (reportnum !== "" || reportnum !== " ") {
        const Id = reportnum;

        const response = await priceIntelligenceInstance().get(
          `/price/getGIAReport/${Id}`
        );
        const { data, autoInputData, status, message } = response?.data;
        if (status !== 200) {
          setLoading(false);
          setErrMsg(message);
          setErrorModal(true);
          return;
        }
        const { msg, code } = data || {};
        const { width, length } = autoInputData || {}
        if (code === 200) {
          setGiaReport(data);
          const isRoundShapeCheck = isShapeRound(autoInputData.shape);
          const polish = diamondPolish?.find((s) => s.displayName === autoInputData.polish)?.code;
          const symmetry = diamondSymmetry?.find((s) => s.displayName === autoInputData.symmetry)?.code;
          const newData = {
            giaReportNumber: reportnum,
            ratio: isRoundShapeCheck ? "1.00" : autoInputData?.ratio,
            length: isRoundShapeCheck ? 1 : autoInputData?.length,
            width: isRoundShapeCheck ? 1 : autoInputData?.width,
            polish,
            symmetry,
          };
          const idu = {
            colorInnerGrade: "2",
            colorDispersion: "3",
            colorUndertone: "3",
          };
          if (checkAssets) {
            setPricestepData({ ...autoInputData, ...newData, ...idu, singlePair: "single" });
          } else {
            setPricestepData({ ...autoInputData, ...newData });
          }
          setLengthValue(length);
          setWidthValue(width);
          setShowButton(false);
          setIsManualPricing(false);
          setShowNextBtn(true);
          const checkPass = checkGIAReport(
            { ...autoInputData, ...newData },
            toast,
            setErrMsg,
          );
          if (!checkPass) {
            setButtonDisable(false);
            setLoading(false);
            setErrorModal(true);
            return;
          }
          navigate(`${"/pricing"}`, {
            state: { action: "fromstartpricing", priceData: checkAssets ? { ...autoInputData, ...newData, ...idu, singlePair: "single" } : { ...autoInputData, ...newData } },
          });
        } else {
          setButtonDisable(false);
          setLoading(false);
          setErrMsg(msg);
          setErrorModal(true);

        }
      } else {
        setButtonDisable(false);
        setLoading(false);
        setGiaNum(false);
        setErrorModal(true);
        setErrMsg("Please enter GIA number");
      }
    } catch (e) {
      setButtonDisable(false);
      setLoading(false);

    }
  };

  const handleOnchange = async (val) => {
    setReportnum(val);
    if (val.length >= 7 && val.length <= 10) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  };
  const routeChangeManual = () => {
    setReportType("byManual");
    navigate(`${"/pricing"}`, {
      state: { action: "fromstartmanualpricing" },
    });
  };

  const handleGia = () => {
    setGiaNum(true);
    setShowNextBtn(true);
  };

  const handleChecked = () => {
    setCheckAssets(!checkAssets);
    localStorage.setItem("checkAssets", !checkAssets);
  };

  const BackTohome = () => {
    setButtonDisable(true);
    setGiaNum(false);
    setReportnum("");

  };
  const [aboutOpen, setAboutOpen] = useState(false);

  const handleChangeAbout = () => {
    setAboutOpen(true);
  }

  const handleCloseAbout = () => {
    setAboutOpen(false);
  }

  console.log("aboutOpen==>", aboutOpen);

  const [barMenuOpen, setBarMenuOpen] = useState();

  const handleMenubar = () => {
    setBarMenuOpen(!barMenuOpen);
  }


  return (
    loader ? <div className="settingloader">
      <div>
        <Spinner animation="border" variant="warning" />
      </div>
    </div> :
      <PriceIntelligenceLayout>
        <div className="start-pricing">
         
          <div className="start-pricing-content start-pricing-desktop-content new-start-pricing">
            <div className="container">
              <div className="start-pricing-header">
                <div>
                  <span>[Beta]</span>
                  <h3>Price Intelligence</h3>
                </div>
                <div className="position-relative">
                  <Button className="start-pricing-menuIcon" onClick={handleMenubar}>
                    <img src={menuicon} alt="menu" />
                  </Button>
                  {barMenuOpen &&
                    <div className="start-menu-pricing">
                      <div className="start-menu-pricing-data" >
                        <Link to="/price-intelligence-search-history" className="start-menu-link">
                          <img src={searchHistory} alt="search-history" /> <p>Search History</p>
                        </Link>

                      </div>
                      <div className="start-menu-pricing-data">
                        <Link className="start-menu-link">
                          <img src={creditIcon} alt="search-history" /> <p>Credits: {(piCredits ==='-1' || piCredits === -1)? 'Unlimited': piCredits}</p>
                        </Link>
                      </div>
                    </div>
                  }


                </div>
              </div>
              <div className="start-pricing-container">
                <div className="start-pricing-banner">
                  <img src={bannerBg} alt="bannerbg" />
                </div>
                <div className="pricing-boxes pricing-boxes-gia">
                  <div>
                    <div className="pricing-boxes-para">
                      {/* <span>[Beta]</span> */}
                      {GiaNum && !loading &&
                        <>
                          <div>
                            <span>[Beta]</span>
                            <p>Yellow, Pink and Blue 1 to 30 carat</p>
                          </div>
                        </>
                      }
                      {!GiaNum && <p>
                        Layering GIA grades with visual assets
                        to uncover wholesale prices
                      </p>}
                      {loading && (
                        <div className="start-pricing-loader text-light">
                          <div className="spinner"></div>
                        </div>
                      )}

                    </div>
                    <div>
                      {!loading && (
                        <div className="start-pricing-form update-start-pricing-form w-100 bg-transparent">
                          <div className="start-pricing-formbox start-pricing-Updatedbox">
                            <div
                              className={`start-pricing-inpbox start-pricing-inpbox-new ${!showButton ? "gia-number-input" : ""
                                }`}
                            >
                              {GiaNum && (
                                <>
                                  <input
                                    type="number"
                                    placeholder="GIA Report Number"
                                    value={reportnum}
                                    onChange={(e) => handleOnchange(e.target.value)}
                                    autoFocus
                                    className="w-100"
                                  />

                                </>
                              )}
                            </div>


                            {showNextBtn && !loading && GiaNum && (
                              <div className="gia-summary-result gia_result_updated">
                                <div className="pricing-userlogin-content-btn ">
                                  <Button
                                    onClick={handleShowReport}
                                    disabled={buttonDisable}
                                  >
                                    Next <img src={arrow} alt="arrow" />{" "}
                                  </Button>
                                </div>
                                <div className="check_value_summary">
                                  {checkAssets && <p className="visual-para">Visual assets are not applied</p>}
                                  <Button className="back_btn" onClick={BackTohome}>
                                    {" "}
                                    <img src={arrow1} alt="arrow" /> Back{" "}
                                   
                                  </Button>
                                </div>
                              </div>
                            )}
                            {!GiaNum && (
                              <Home
                                handleChecked={handleChecked}
                                checkAssets={checkAssets}
                                handleGia={handleGia}
                                routeChangeManual={routeChangeManual}
                              />
                            )}

                          </div>
                          {!GiaNum && <h4 className="confidential-mobile-text"> Confidential </h4>}
                          {/* <h4 className="confidential-desktop-text confidential-desktop-text-update"> Confidential </h4> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="pricing-boxes about-price-boxes">
                  <div className="">
                    <div className={` ${aboutOpen ? "about-header" : " d-flex justify-content-between"}`} onClick={handleChangeAbout} role="presentation">

                      <div className="para-about d-flex gap-2">
                        <div className="text-center rotate-180 mob-arrow-dow" role="presentation">
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                            <path d="M8.03018 0.45579C7.48021 -0.156573 6.52059 -0.156572 5.97062 0.455791L0.443585 6.60993C-0.356657 7.50097 0.275725 8.91889 1.47336 8.91889L12.5274 8.91889C13.7251 8.91889 14.3575 7.50097 13.5572 6.60993L8.03018 0.45579Z" fill="#0D1949" />
                          </svg>
                        </div>
                        <p>About the price Intelligence</p>
                      </div>
                      {/* <div className="desk-arrow-down">
                        <img src={arrowDown} alt="arrowDown" />
                      </div> */}
                    </div>
                    <div className={`para-about  ${aboutOpen ? "para-about-text" : "d-none"}`}>
                      <p >
                        Prices may fluctuate over time with market trends and are contingent upon the accuracy of your
                        input; therefore, they should not be considered guaranteed. While the price range
                        reflects values within the trade with high accuracy, we recommend using it as a second opinion.
                      </p>
                      <div className="text-center mt-3" onClick={handleCloseAbout} role="presentation">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                          <path d="M8.03018 0.45579C7.48021 -0.156573 6.52059 -0.156572 5.97062 0.455791L0.443585 6.60993C-0.356657 7.50097 0.275725 8.91889 1.47336 8.91889L12.5274 8.91889C13.7251 8.91889 14.3575 7.50097 13.5572 6.60993L8.03018 0.45579Z" fill="#0D1949" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       


        </div>
        <Footer />
        <DisclaimerModal
        disclaimerModal={disclaimerModal}
        setDisclaimerModal={setDisclaimerModal}

        
        
        />
        <ErrorModal
          errorModal={errorModal}
          setErrorModal={setErrorModal}
          setLoading={setLoading}
          errMsg={errMsg}
          bg_remove_modal={"bg_remove_modal"}
        />

      </PriceIntelligenceLayout>


  );
};

export default StartPricing;
